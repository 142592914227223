<template>
  <div >
    <!-- <Layout>
      <Header></Header>
      <Content> -->
          <Row justify="center" style="margin-top:100px"> 
            <Card style="width: 320px;">
                <Row justify="center">
                  <img src="@/assets/app_logo.svg" style="width:200px;padding:16px;"/>

                <Form ref="loginFormFields" style="width:100%" :model="loginFormFields" :rules="loginFieldRules" inline >
                    <FormItem prop="email" >
                        <Input ref="email" type="text" prefix="ios-person-outline" v-model="loginFormFields.email" placeholder="Email" style="width: 280px;">
                            <!-- <Icon type="ios-person-outline" slot="prepend"></Icon> -->
                        </Input>
                    </FormItem>

                    <FormItem prop="password">
                        <Input ref="password" type="password" password prefix="ios-lock-outline" v-model="loginFormFields.password" v-on:on-enter="handleSubmit()" placeholder="Password" style="width: 280px;">
                            <!-- prepend slot does not work with password eye reveal, so use prefix icon method instead -->
                            <!-- <Icon type="ios-lock-outline" slot="prepend"></Icon> -->
                        </Input>
                    </FormItem>

                      <Checkbox v-model="rememberMe">Remember my email</Checkbox><br />

                      <span style="line-height: 12px" class="finePrint">By clicking on 'Login' I acknowledge that I have read and agree to 
                        <a style="text-decoration:underline;" target="_new" href="https://prod-content.bntxinteract.com/legal/bntx-terms-and-conditions.pdf">Terms &amp; Conditions</a>
                        and <a style="text-decoration:underline;" target="_new" href="https://www.bntxinteract.com/privacy-policy">Privacy Policy</a>
                        of BnTx Interact.
                      </span>
                      <br /><br />
                    <FormItem style="width:100%">
                        <Button type="primary"
                                @click="handleSubmit"
                                style="width: 100%;"
                                :loading="loginInProgress">
                          <span v-if="!loginInProgress">Login</span>
                          <span v-else>Authenticating…</span>
                        </Button>
                        <Button @click="showForgotPasswordUI = true" style="width: 100%; margin-top:8px">Forgot Password</Button>
                        <br />

                        <Button @click="handleRegister" style="width: 100%; margin-top:16px">Register</Button>
                    </FormItem>
                </Form>
                </Row>

                <Row>
                  <Drawer
                    v-model="showForgotPasswordUI"
                    width="500"
                    :styles="detailsDrawerStyles">
                        <p slot="header"><center>Forgot Password</center></p>

                        Please enter the email address you used to create your account and we will send you a link to reset your password.<br /><br />

                        <Input type="text" v-model="loginFormFields.email" placeholder="Email" style="width: 280px;" /><br /><br />

                        <Button   type="primary"
                                  style="width: 100%; margin-top:16px"
                                  :loading="resetPasswordInProgress"
                                  @click="handleResetPassword">
                            <span v-if="!resetPasswordInProgress">Reset Password</span>
                            <span v-else>Requesting Reset... please wait.</span>
                        </Button>

                </Drawer>
              </Row>

                <Row justify="start">
                  <span class="finePrint">
                    This software is only supported using the Chrome browser
                    <a style="text-align: leftx;text-decoration: underline;display: block;" target="_new" href="https://www.google.com/intl/en_au/chrome/">Download Chrome</a>

                    <br />
                    <br />
                    {{ bntxVersion }}<br />
                    Copyright @{{ copyrightYear }} Neurology Interact
                  </span>
                </Row>
            </Card>
        </Row>
        <!-- <Row type="flex" justify="center" align="bottom" style="height:150px">
            <span style="font-family: Calibri, sans-serif; font-size:16pt; font-style: italic; font-weight: 500; padding: 0 16px 8px 0">Quality Use of Medicines Partner</span>
            <img :src="'./images/partner-logo.png'" style="height:50px"/>
        </Row> -->
      <!-- </Content>
      <Footer><i-copyright /></Footer>
    </Layout> -->
  </div>
</template>

<script>

const {CONFIG} = require('@/js/bntx-config')
import AnalyticsMgr from '@/js/AnalyticsManager.js';
const {itBnTx} = require('@/js/itBnTx')

import Vue from 'vue'

// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'

import VueNativeSock from 'vue-native-websocket'
// Vue.use(VueNativeSock, 'ws://localhost:9090', { protocol: 'my-protocol' })
Vue.use(VueNativeSock, 'ws://localhost:9090', {
  connectManually: true,
})




export default {
  name: 'Login',
  props: {
    
  },

  created () {
    var email = localStorage.getItem("physicianEmail")

    if (email != undefined) {
      this.loginFormFields.email = email
      this.rememberMe = true
    }

    AnalyticsMgr.sendPageView('/LoginScreen')
  },
  mounted() {
    
    //console.log("LOGIN", this.$route.query, this.$route.query.checkout != undefined)

    if (this.$route.query.checkout != undefined) {
      this.$store.commit("checkoutData", { isCheckoutCallback: true, status : "success" });

      this.loginFormFields.email = "user"
      this.loginFormFields.password = "pass"
      this.handleSubmit()
    } else {
      this.$store.commit("checkoutData", { isCheckoutCallback: false });

      this.$refs.email.focus();
      this.$gtag.pageview(this.$route)
    }

    document.title = "BnTx INTERACT"
  },

  data () {
    return {
        loginFormFields: {
            email: '',
            password: ''
        },
        loginFieldRules: {
            email: [
                { required: true, message: 'Email cannot be empty', trigger: 'blur' },
                { type: 'email', message: 'Incorrect email format', trigger: 'blur' }
            ],
            password: [
                { required: true, message: 'Please fill in the password.', trigger: 'blur' },
                { type: 'string', min: 6, message: 'Password too short', trigger: 'blur' }
            ]
        },

        rememberMe : false,

        resetPasswordInProgress: false,
        loginInProgress : false,

        showForgotPasswordUI: false,
        detailsDrawerStyles: {
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static'
        },


        websocket: null,

      }
    },

  computed: {
    copyrightYear() {
      return new Date().getFullYear()
    },

    bntxVersion() {
      return CONFIG.FRONTEND_VERSION
    },
    
  },

  methods: {
        handleSubmit() {

            AnalyticsMgr.sendEvent('/LoginScreen', 'click', 'Login')

            this.$refs['loginFormFields'].validate((valid) => {
                if (valid) {
                  
                  this.loginInProgress = true

                  itBnTx.login({
                                 inEmail : this.loginFormFields.email,
                              inPassword : this.loginFormFields.password
                                })
                        .then(response => {

                            AnalyticsMgr.sendEvent('/LoginResult', 'api', 'Success')

                            if (this.rememberMe) {
                              localStorage.setItem("physicianEmail", this.loginFormFields.email)
                            } else {
                              localStorage.removeItem("physicianEmail")
                            }

                            this.$Message.success('Login successful. Have a good day!');

// // trial
// response.userDetails.accountStatus = 'T'
// response.userDetails.daysLeft = -1           // 50, 1, -1
// paid
// response.userDetails.accountStatus = 'P'
// response.userDetails.daysLeft = -32            // 30, 13
// response.userDetails.paymentMethodIssueExists = true

                            // code to assist UI test automation... hopefully not a loophole for users to get free access
                            // as hopefully we cover it via API restrictions too.
                            if (this.$route.query.simui != undefined) {
                              // value is of format "<account status>,<days left>"
                              // OR value is of format "P,<days left>,<paymentMethodIssueExists>"
                              var values = this.$route.query.simui.split(",")
                              if (values.length >= 2) {
                                if (values[0] == 'T' || values[0] == 'P')
                                  response.userDetails.accountStatus = values[0]

                                response.userDetails.daysLeft = parseInt(values[1])

                                if (values.length == 3) {
                                  response.userDetails.paymentMethodIssueExists = parseInt(values[2]) != 0
                                }
                              }
                            }

                            this.$store.commit("loggedInDoctorDetails", response.userDetails )
                            
                            itBnTx.clearAllCache()  // init to well defined state. as logout not always called... eg when idle timeout kicks in etc etc.

                            var loggedInPracticeData = itBnTx.getPractise({inPractiseID : this.$store.state.loggedInDoctorDetails.practiseId })
                            var conditionData = itBnTx.getAllConditions()
                            var genderData = itBnTx.getGenders()
                            var midasData = itBnTx.getAllMidasQuestions()
                            var muscleData = itBnTx.getMuscleImagesData()
                            var default_mandatoryPatientDOB = itBnTx.getPractisePref({inPrefKey : "_btxw.default-mandatory-patient-dob", inDefaultValue : "1"});
                            var default_mandatoryPatientReferrerPhysician = itBnTx.getPractisePref({inPrefKey : "_btxw.default-mandatory-patient-referrer-physician", inDefaultValue : "1"});
                            var default_defaultDateInputFormat = itBnTx.getPractisePref({inPrefKey : "_btxw.default-date-input-format", inDefaultValue : "dd-MM-yyyy"});

                            Promise.all([loggedInPracticeData,
                                         conditionData,
                                         genderData,
                                         midasData,
                                         muscleData,
                                         default_mandatoryPatientDOB,
                                         default_mandatoryPatientReferrerPhysician,
                                         default_defaultDateInputFormat
                                         ]).then(values => {
                              // results come back in same order as promise array passed in.
//if (this.$route.query.devui != undefined) {
  // values[0].data.supportsCoPayments = true        // TESTING!!!!
//}
                              this.$store.commit("loggedInPracticeDetails", values[0].data)
                              this.$store.commit("refDataConditions", values[1].data)
                              this.$store.commit("defaultMandatoryPatientDOB", values[5].data.value == "1")
                              this.$store.commit("defaultMandatoryPatientReferrerPhysician", values[6].data.value == "1")
                              this.$store.commit("defaultDateInputFormat", values[7].data.value)

                              this.$store.commit("currentNavMenuSelectionID", '')   // reset to init value, otherwise if we persist it from a previous session, and the selection is
                                                                                    // say the injection screen, then when we login this time and user tries to navigate to 
                                                                                    // injection screen, then nothing happens, as the state in the store is already this value
                                                                                    // and so because it not changed in value, listeners for that state wont trigger and 
                                                                                    // the UI will not change. This was a hard to find bug.
// const options = { path: '/rest/ws/notify',
//                   auth: {
//                     token: this.$store.state.btxAuthToken
//                   },
//                 }; //Options object to pass into SocketIO

// Vue.use(new VueSocketIO({
//     debug: true,
//     connection: SocketIO('wss://test-inject.bntxinteract.com', options), //options object is Optional
    
//   })
// );

try {
// this.websocket = new WebSocket("wss://test-inject.bntxinteract.com/rest/ws/notify?t=" + this.$store.state.btxAuthToken);
// this.websocket = new WebSocket("ws://test-inject.bntxinteract.com/rest/ws/notify?t=" + this.$store.state.btxAuthToken);
// this.websocket = new WebSocket("wss://test-inject.bntxinteract.com/rest/ws/notify?t=" + this.$store.state.btxAuthToken);

//         this.websocket.onerror = function (event) {
//             console.log("error:", event);
//         }

//         this.websocket.onopen = function (event) {
//             console.log("OPEN", event);
//         }

//         this.websocket.onmessage = function (event) {
//             console.log("MESSAGE", event.data);
            
//         }

//         this.websocket.onclose = function () {
//             console.log("CLOSE");
//         }
    this.$connect(itBnTx.baseWebSocketURL + '/rest/ws/notify?t=' + this.$store.state.btxAuthToken, {
      reconnection: true, // (Boolean) whether to reconnect automatically (false)
      reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
      reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
    })
} catch (err) {
  console.log(err)
}



                              // all our reference data is loaded... so we can now safely switch to our UI to load.
                              this.$router.push({name:"bntx-home"});

//console.log("LOGIN", this.$store.state.checkoutData)
                              if (this.$store.state.checkoutData.isCheckoutCallback == true) {
                                // if we get here, we should have something selected...so simply switch the nav manu to the assessment section.
                                this.$store.commit("currentNavMenuSelectionID", 'admin-billing')
                                setTimeout(()=>{ this.$store.commit("currentNavMenuSelectionID", '') }, 300)
                                setTimeout(()=>{ this.$store.commit("currentNavMenuSelectionID", 'admin-billing') }, 1000)
                              }

                              this.loginFormFields.email = ""
                              this.loginFormFields.password = ""
                            })
                            .catch(error => {
                             console.log( error)
                              this.$Message.error('Loading some initialization data appears to have failed.');
                            })

                        })
                        .catch(err => {                            
                            AnalyticsMgr.sendEvent('/LoginResult', 'api', 'Failure_' + err.responseCode)
                            
                            this.$Message.error(err.responseMsg);
                        })
                        .finally(() => {
                          this.loginInProgress = false
                        });

                } else {
                    self.$Message.error('Check credentials before trying again');
                }
            })
        },

        handleResetPassword() {
          
          AnalyticsMgr.sendEvent('/LoginScreen', 'click', 'ForgotPassword')

          this.resetPasswordInProgress = true

          itBnTx.resetPassword({inEmail : this.loginFormFields.email})
                .then(response => {
                  response
                  this.$Message.success('Look out for an email and reset your password.');
                  this.showForgotPasswordUI = false
                  this.resetPasswordInProgress = false
                })

        },

        handleRegister() {
          //window.open(CONFIG.BNTX_REGISTER_URL, '_blank');
          this.$router.push({name:"register"});
        }
    },

    watch: {
      "loginFormFields.email" :            function() { this.$refs['loginFormFields'].validate() },
      "loginFormFields.password" :         function() { this.$refs['loginFormFields'].validate() },

      "rememberMe" :                       function() { if (this.rememberMe == false) localStorage.removeItem("physicianEmail") },



    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}



.ivu-layout-header {
  background: #FFFFFF;
}

.ivu-layout-content {
  background: #FFFFFF;
}


.finePrint {
  font-size: 10px;
  line-height: 12px
}

.ivu-card {
    background-color: #eff4f4 !important;
    padding-top: 0px
}

</style>

<style>
/* body {
  background: #334155 !important;
} */


</style>
