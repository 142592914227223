// Load Reference Data 'constants'
const {REFDATA} = require('@/js/reference-data.js')

// load the itBnTx module to assist with identifying the environment determined at runtime
const {itBnTx} = require('@/js/itBnTx')

const CONTENT_DOMAIN = () => { return (itBnTx.environment == 'prod') 
									? 'https://prod-content.bntxinteract.com'
									: 'https://test-content.bntxinteract.com'
								}

// ##################
// ### DONT FORGET TO ALSO CONFIGURE @/js/itBnTx/index.js for the different environments for API's
// ##################

var CONFIG = {
	DEV_DEBUG								: false,

	FRONTEND_VERSION						: "v2.7.0r3",

	GOOGLE_ANALYTICS_ID						: () => {
												return (itBnTx.environment == 'prod') 
												? 'UA-62824770-2'
												: 'UA-62824770-3'
											},
	
	STRIPE_PUBLISHABLE_KEY: itBnTx.environment == "prod"
											? "pk_live_51It5KsIKIxEKoiJRLMp0c0jnpIvcV9DM9BCcSPvXxQCi3IGmBVMx6qTlS8o4eXHIpDT9eOhE9YVhUnmt8n5bdeFe00eHTyFoVl"
											: "pk_test_51It5KsIKIxEKoiJR4UqdkZpCrhX5eapLlAW98SNbjWDjEppqv2yuBQ5Kqq7NNMXV4XvoSjxk3UE7kglPfL3WqDyM00iCpdUa0R",

	CAPTCHA_SITE_KEY						: "6LcMAwocAAAAAPw7lEOSqqn7PewPI5d2EfH22Y6L",
	ADDRESSFINDER_KEY						: "L6VMNREWACUGJP73QFYH",

	IDLE_ACTIVITY_DURATION_IN_SECONDS		: 1800,

	PATIENT_CONSENT_FORM_URL				: () => { return CONTENT_DOMAIN() + "/legal/bntx-patient-consent.pdf" },
	COPAYMENT_CONSENT_FORM_URL				: () => { return CONTENT_DOMAIN() + "/legal/bntx-copayment-consent-v1.pdf" },
	REFERRING_PHYSICIAN_IMPORT_TEMPLATE_URL : () => { return CONTENT_DOMAIN() + "/support/referring-physician-template-v1.csv" }, 
	PATIENT_IMPORT_TEMPLATE_URL				: () => { return CONTENT_DOMAIN() + "/support/patient-template-v1.csv" },
	USER_GUIDE_URL							: () => { return CONTENT_DOMAIN() + "/legal/bntx-userguide.pdf" },
	SUPPORT_EMAIL							: "mailto:support@bntxinteract.com",
	BNTX_REGISTER_URL						: "https://bntxinteract.com",

	USER_PREF_PREFIX						: "_btxw.",

}



CONFIG = Object.assign(CONFIG, REFDATA)


module.exports = {
    CONFIG,
};